import React, { useState } from 'react';
import LocationIcon from '../../../static/assets/job/place.svg';
import WorkIcone from '../../../static/assets/job/work.svg';
import ApplyNowModal from '../applyNowModal';

// This file is used at : [ devops.js | marketing-manager.js | project-management.js | project-manager.js | quality-assurance.js |
//                          senior-back-end.js | senior-content-manager.js | senior-customer-support.js | senior-front-end.js |
//                          senior-technical-write.js | senior-ux-designer.js | social-media-marketing-specialist.js ]

const JobHomeComponent = props => {
  const {
    job,
    jobPlace,
    jobTitle,
    jobDescription,
    applyButton,
    learnButton,
    onLearnMoreClick,
  } = props;

  const [isModalApplyOpen, setisModalApplyOpen] = useState(false);

  return (
    <div className='job-home-section'>
      <div className='job-home-section-bg'>
        <div className='full-container'>
          <div className='job-home-section-wrapper'>
            <div className='job-home-section-location'>
              <LocationIcon className='job-home-section-location-icon' />
              <p className='job-home-section-location-text'>{jobPlace}</p>
              <WorkIcone className='job-home-section-location-icon' />
              <p className='job-home-section-location-text'>{job}</p>
            </div>
            <h1 className='job-home-section-title'>{jobTitle}</h1>
            <p
              className='job-home-section-description'
              dangerouslySetInnerHTML={{ __html: jobDescription }}
            ></p>
            <div className='job-home-section-buton'>
              <button
                onClick={() => setisModalApplyOpen(true)}
                className='job-home-section-button-apply'
              >
                {applyButton}
              </button>
              <button
                className='job-home-section-button-learn'
                onClick={onLearnMoreClick}
              >
                {learnButton}
              </button>
            </div>
          </div>
        </div>
      </div>
      <ApplyNowModal
        isOpen={isModalApplyOpen}
        onClose={() => setisModalApplyOpen(false)}
      />
    </div>
  );
};
export default JobHomeComponent;
