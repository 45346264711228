import React, { useEffect } from "react";

// This file is used at : [ jobComponent.js | jobHomeComponent.js | ourStaffHomeComponent.js | careers.js ]

const ApplyNowModal = ({ isOpen, onClose }) => {
  const handleModalClose = () => {
    onClose();
  };

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflowY = "hidden ";
    } else {
      document.body.style.overflowY = "auto";
    }
  }, [isOpen]);

  const CloseIcon = () => (
    <svg
      viewBox="0 0 38 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="home-section-modal-message-btn-close"
    >
      <path
        d="M18.9992 15.1442L33.4367 0.113647L37.5608 4.40723L23.1233 19.4378L37.5608 34.4684L33.4367 38.762L18.9992 23.7314L4.56167 38.762L0.4375 34.4684L14.875 19.4378L0.4375 4.40723L4.56167 0.113647L18.9992 15.1442Z"
        fill="#020406"
      />
    </svg>
  );

  const EmailSvg = () => (
    <svg
      width="21"
      height="18"
      viewBox="0 0 21 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.5 0H19.5C19.7652 0 20.0196 0.105357 20.2071 0.292893C20.3946 0.48043 20.5 0.734784 20.5 1V17C20.5 17.2652 20.3946 17.5196 20.2071 17.7071C20.0196 17.8946 19.7652 18 19.5 18H1.5C1.23478 18 0.98043 17.8946 0.792893 17.7071C0.605357 17.5196 0.5 17.2652 0.5 17V1C0.5 0.734784 0.605357 0.48043 0.792893 0.292893C0.98043 0.105357 1.23478 0 1.5 0ZM18.5 4.238L10.572 11.338L2.5 4.216V16H18.5V4.238ZM3.011 2L10.561 8.662L18.002 2H3.011Z"
        fill="#020406"
      />
    </svg>
  );

  return (
    <>
      {isOpen && (
        <>
          <div className={`full-screen-modal ${isOpen ? "is-open" : ""}`}>
            <div className="full-screen-modal-overlay">
              <div className="home-section-modal-message">
                <button
                  onClick={handleModalClose}
                  className="home-section-modal-message-btn"
                >
                  <CloseIcon style={{ color: "white" }} />
                </button>

                <div
                  className="home-section-modal-message-video"
                  style={{
                    color: "#020406",
                    textAlign: "center",
                    marginTop: "40px",
                  }}
                >
                  <h3
                    style={{
                      fontSize: "16px",
                    }}
                  >
                    Please send an email with your CV for the position you are
                    applying.
                  </h3>
                  <div
                    className="job-section-email"
                    style={{
                      marginTop: "25px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <EmailSvg className="job-section-email-icon" />
                    <a
                      href="mailto:contact@motomtech.com"
                      className="job-section-email-text"
                      style={{
                        color: "#020406",
                        textDecoration: "none",
                      }}
                    >
                      contact@motomtech.com
                    </a>
                  </div>
                  <a
                    style={{
                      marginTop: "25px",
                    }}
                    className="pricing-section-wrapper-swiper-links-link pricing-section-wrapper-swiper-links-link--secondary "
                    href="mailto:contact@motomtech.com"
                  >
                    Send Email
                  </a>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ApplyNowModal;
