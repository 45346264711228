import React, { useState } from "react";
import EmailContact from "../../../static/assets/email.svg";
import PhoneContact from "../../../static/assets/phone.svg";
import ApplyNowModal from "../applyNowModal";

// This file is used at : [ devops.js | marketing-manager.js | project-management.js | project-manager.js | quality-assurance.js |
//                          senior-back-end.js | senior-content-manager.js | senior-customer-support.js | senior-front-end.js |
//                          senior-technical-write.js | senior-ux-designer.js | social-media-marketing-specialist.js ]

const JobComponent = (props) => {
  const {
    jobCompanyDescription,
    jobContact,
    jobContactName,
    jobContactPosition,
    jobContactButton,
    learnMoreRef,
    test,
  } = props;

  const [isModalApplyOpen, setIsModalApplyOpen] = useState(false);

  return (
    <div className="job-section" ref={learnMoreRef}>
      <div className="full-container">
        {test ? (
          <p className="job-section-description">
            <h4>Company</h4>
            <p>
              MotomTech LLC is a Software Development company headquartered in
              Utah, USA and also has an operational office located in Tirana,
              Albania. We provide agile software development to our customers
              based in the USA.
            </p>
            <h4>Job Description</h4>
            <p>
              The ideal candidate will have experience with full-stack
              development and be comfortable working with a variety of
              technologies. You will be responsible for developing new features
              and maintaining existing ones, as well as collaborating with other
              developers and stakeholders to ensure high-quality software
              delivery.
            </p>
            <h4>Responsibilities:</h4>
            <ul>
              <li>
                Collaborate with cross-functional teams to design, develop, and
                implement new features
              </li>
              <li>Design, maintain and improve existing software</li>
              <li>Write clean, maintainable code using best practices</li>
              <li>Participate in code reviews and help ensure code quality</li>
              <li>
                Stay up-to-date with emerging trends and technologies in
                software development
              </li>
              <li>
                Integration of user-facing elements developed by front-end
                developers with server side logic
              </li>
              <li>
                Identify bottlenecks and bugs, and devise solutions to these
                problems
              </li>
            </ul>
            <h4>Requirements:</h4>
            <ul>
              <li>
                At least 2 years of experience with Ruby on Rails development
              </li>
              <li>
                Strong understanding of web technologies, such as HTML, CSS, and
                JavaScript
              </li>
              <li>
                Experience with front-end frameworks such as React or Angular is
                a plus
              </li>
              <li>
                Knowledge of relational databases such as MySQL or PostgreSQL
              </li>
              <li>Familiarity with version control systems such as Git</li>
              <li>Excellent problem-solving and communication skills</li>
            </ul>
          </p>
        ) : (
          <p
            className="job-section-description"
            dangerouslySetInnerHTML={{ __html: jobCompanyDescription }}
          ></p>
        )}
        <div className="job-section-contact">
          <p className="job-section-contact-title">{jobContact}</p>
          <div className="row">
            <div className="col-md-6">
              <p className="job-section-contact-name">{jobContactName}</p>
              <p className="job-section-contact-position">
                {jobContactPosition}
              </p>
            </div>
            <div className="col-md-6 job-section-col">
              <div className="job-section-email">
                <EmailContact className="job-section-email-icon" />
                <p className="job-section-email-text">contact@motomtech.com</p>
              </div>
              <div className="job-section-email">
                <PhoneContact className="job-section-email-icon" />
                <p className="job-section-email-text">(385) 217-5559</p>
              </div>
            </div>
          </div>
          <button
            onClick={() => setIsModalApplyOpen(true)}
            className="job-section-contact-button"
          >
            {jobContactButton}
          </button>
        </div>
      </div>
      <ApplyNowModal
        isOpen={isModalApplyOpen}
        onClose={() => setIsModalApplyOpen(false)}
      />
    </div>
  );
};
export default JobComponent;
